const QUERIES = {
  USERS_LIST: 'users-list',
  COLORS_LIST: 'color-list',
  BRANDS_LIST: 'brand-list',
  CATEGORY_LIST: 'category-list',
  PROFILE: 'user-profile',
  SIZE_LIST: 'size-list',
  PRODUCT_LIST: 'product-list',
  MATERIAL_LIST: 'material-list',
}

export { QUERIES }
