import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/brand" icon="element-11" title="Brand" />
      <AsideMenuItem to="/color" icon="element-11" title="Color" />
      <AsideMenuItem to="/size" icon="element-11" title="Size" />
      <AsideMenuItem to="/material" icon="element-11" title="Material" />
      <AsideMenuItem to="/category" icon="element-11" title="Category" />
      <AsideMenuItem to="/products" icon="element-11" title="Product" />
      <AsideMenuItem to="/users" icon="element-11" title="User" />
      {window.location.pathname === '/user/change-password' && <AsideMenuItem to="/user" icon="element-11" title="Change Password" />}
      {window.location.pathname === '/profile/update' &&
      <AsideMenuItem to="/profile" icon="element-11" title="Profile" />}
    </>
  );
}
