import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const ChangePasswordPage = lazy(
    () => import("../modules/changePassword/ChangePasswordPage")
  );
  const CategoryPage = lazy(
    () => import("../modules/apps/category/CategoryPage")
  );
  const ProductPage = lazy(() => import("../modules/apps/product/ProductPage"));
  const UsersPage = lazy(() => import("../modules/apps/user/UsersPage"));
  const BrandPage = lazy(() => import("../modules/apps/brand/BrandPage"));
  const SizePage = lazy(() => import("../modules/apps/size/SizePage"));
  const ColorPage = lazy(() => import("../modules/apps/color/ColorPage"));
  const MaterialPage = lazy(() => import("../modules/apps/material/MaterialPage"));
  const UpdateProfilePage = lazy(
    () => import("../modules/profile/ProfilePage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/users" />} />
        <Route path="brand" element={<BrandPage />} />
        <Route
          path="brand/*"
          element={
            <SuspensedView>
              <BrandPage />
            </SuspensedView>
          }
        />
        <Route
          path="category/*"
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="material/*"
          element={
            <SuspensedView>
              <MaterialPage />
            </SuspensedView>
          }
        />
        <Route
          path="size/*"
          element={
            <SuspensedView>
              <SizePage />
            </SuspensedView>
          }
        />
        <Route
          path="color/*"
          element={
            <SuspensedView>
              <ColorPage />
            </SuspensedView>
          }
        />
        <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path="users/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="user/*"
          element={
            <SuspensedView>
              <ChangePasswordPage />
            </SuspensedView>
          }
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <UpdateProfilePage />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
